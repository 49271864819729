<ng-select class="custom custom-multi"
  labelForId="cc"
  #selectRef
  placeholder="{{'MEMOS.SELECT'|translate}}"
  [multiple]="false"
  [items]="ccList$ | async"
  [typeahead]="ccListInput$"
  [loading]="loading$ | async"
  [typeToSearchText]="'user.type-search' | translate"
  [notFoundText]="'SELECT.No items found'|translate"
  [closeOnSelect]="true"
  [clearSearchOnAdd]="true"
  (change)="onCCChange($event, selectRef)">
  <ng-template ng-label-tmp
    let-item="item"
    let-clear="clear">
    <span class="ng-value-label">{{ (lang$|async) === 'en' ? item.context?.name_en || item.label : item.label }}</span>
    <span class="ng-value-icon right"
      (click)="clear(item)"
      aria-hidden="true">×</span>
  </ng-template>

  <ng-template ng-option-tmp
    let-item="item">
    {{ (lang$|async) === 'en' ? item.context.name_en : item.label }}
    <span class="badge badge-info ml-1 w-auto"
      *ngIf="item.context.is_group">GROUP</span><br>
    <small>{{ item.context.is_group ? ('MEMOS.MEMBER-CC' | translate) : 'E-mail' }}: {{ item.context.text }}</small>
  </ng-template>
</ng-select>
