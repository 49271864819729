import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApprovalDropdownModule } from '../modules/memos/components/memo-creation/approval-dropdown/approval-dropdown.module';
import { CommaPipe } from './pipes/comma.pipe';
import { PermissionDirective } from './directives/permission.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PreviewPdfComponent } from './components/preview-pdf/preview-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { DropFileComponent } from './components/drop-file/drop-file.component';
import { FormValidationDirective } from './directives/form-validation.directive';
import { FormSubmitValidationDirective } from './directives/form-submit-validation.directive';
import { SignatureComponent } from './components/signature/signature.component';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { BackButtonDirective } from './directives/back-button.directive';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { SignSignatureComponent } from './components/sign-signature/sign-signature.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DepartmentDropdownComponent } from './components/department-dropdown/department-dropdown.component';
import { SetThemeDirective } from './directives/set-theme.directive';
import { TreeModule } from '@circlon/angular-tree-component';
import { LaddaModule } from 'angular2-ladda';
import { LoaNameThPipe } from './pipes/loa-name-th.pipe';
import { HumanDatetimePipe } from './pipes/human-datetime.pipe';
import { ColorSketchModule } from 'ngx-color/sketch';
import { HammerModule } from '@angular/platform-browser';
import { ImageUploadPreviewComponent } from './components/image-upload-preview/image-upload-preview.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { UploadFileExcelComponent } from './components/upload-file-excel/upload-file-excel.component';
import { MemoPlaneTicketComponent } from './components/memo-plane-ticket/memo-plane-ticket.component';
import { CommentComponent } from './components/comment/comment.component';
import { AutosizeModule } from 'ngx-autosize';
import { MentionModule } from 'angular-mentions';
import { ApprovalConfirmModalComponent } from './components/approval-confirm-modal/approval-confirm-modal.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SelectSignMethodModalComponent } from './components/select-sign-method-modal/select-sign-method-modal.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DeleteConfirmationModalComponent } from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ImportProgressComponent } from './components/import-progress/import-progress.component';
import { TreeDropdownModule } from './components/tree-dropdown/tree-dropdown.module';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SecurePipe } from './pipes/secure.pipe';
import { MentionHighlightDirective } from './directives/mention-highlight.directive';
import { ContentEditableDirective } from './directives/content-editable.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BuddhistDatePipe } from './pipes/date.pipe';
import { ImageProcessingService } from './service/image-processing.service';
import { LinkifyPipe } from '../modules/memos/components/upload-memos/pdf-signature-customizer/pipe/linkfy.pipe';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ExportExcelModalComponent } from './components/export-excel-modal/export-excel-modal.component';
import { PasswordRuleBoxComponent } from './components/pasword-rule-box/password-rule-box.component';
import { PhonePipe } from './pipes/phone.pipe';
import { TreeDropdownAccessServiceModule } from './components/tree-dropdown-access-service/tree-dropdown-access-service.module';
import { TreeDropdownBuildingModule } from './components/tree-dropdown-building/tree-dropdown-building.module';
import { AngularResizedEventModule } from 'angular-resize-event';
import { PermissionNotFoundComponent } from './components/permission-not-found/permission-not-found.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { Department1ToCompanyPipe } from './pipes/department1-to-company.pipe';
import { GlobalAdminLabelPipe } from './pipes/global-admin-label.pipe';
import { SplitPipe } from './pipes/split.pipe';
import {
  CommonModalComponent,
  CustomModalBodyDirective as ImplementCustomModalBodyDirective,
  CustomModalFooterDirective as ImplementCustomModalFooterDirective,
  CustomModalHeaderDirective as ImplementCustomModalHeaderDirective,
} from './components/common-modal/common-modal.component';
import { CardComponent } from './components/card/card.component';
import { CCSelectInputComponent } from './components/cc-select-input/cc-select-input.component';
import {
  CustomModalBodyDirective,
  CustomModalFooterDirective,
  CustomModalHeaderDirective,
  ModalConfirmationComponent,
} from './components/modal-confirmation/modal-confirmation.component';

export function createTranslateLoader(
  http: HttpClient,
): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Local Components

@NgModule({
  imports: [
    ApprovalDropdownModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    NgSelectModule,
    NgbModule,
    TranslateModule,
    NgxSpinnerModule,
    CarouselModule,
    PdfViewerModule,
    HammerModule,
    CanvasWhiteboardModule,
    ImageCropperModule,
    AngularSvgIconModule.forRoot(),
    TreeModule,
    LaddaModule,
    ColorSketchModule,
    AutosizeModule,
    MentionModule,
    NgOtpInputModule,
    ClickOutsideModule,
    TreeDropdownModule,
    NgxFileDropModule,
    TreeDropdownAccessServiceModule,
    TreeDropdownBuildingModule,
    AngularResizedEventModule,
  ],
  exports: [
    ApprovalDropdownModule,
    LoadingSpinnerComponent,
    ReactiveFormsModule,
    AngularSvgIconModule,
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    NgxSpinnerModule,
    CommaPipe,
    PermissionDirective,
    CarouselModule,
    PreviewPdfComponent,
    PdfViewerModule,
    SvgIconComponent,
    DropFileComponent,
    FormValidationDirective,
    FormSubmitValidationDirective,
    SignatureComponent,
    BackButtonDirective,
    ScrollTrackerDirective,
    DateRangePickerComponent,
    SignSignatureComponent,
    ImageCropperModule,
    DepartmentDropdownComponent,
    SetThemeDirective,
    DeleteConfirmationModalComponent,
    LaddaModule,
    LoaNameThPipe,
    HumanDatetimePipe,
    ColorSketchModule,
    ImageUploadPreviewComponent,
    PdfViewerComponent,
    UploadFileExcelComponent,
    MemoPlaneTicketComponent,
    CommentComponent,
    ApprovalConfirmModalComponent,
    SelectSignMethodModalComponent,
    PaginationComponent,
    ImportProgressComponent,
    ClickOutsideModule,
    FeatureFlagDirective,
    OtpModalComponent,
    TreeDropdownModule,
    SecurePipe,
    MentionHighlightDirective,
    ContentEditableDirective,
    SafeHtmlPipe,
    LinkifyPipe,
    ConfirmModalComponent,
    ExportExcelModalComponent,
    PasswordRuleBoxComponent,
    PhonePipe,
    TreeDropdownAccessServiceModule,
    TreeDropdownBuildingModule,
    PermissionNotFoundComponent,
    Department1ToCompanyPipe,
    GlobalAdminLabelPipe,
    SplitPipe,
    CardComponent,
    CommonModalComponent,
    ImplementCustomModalHeaderDirective,
    ImplementCustomModalBodyDirective,
    ImplementCustomModalFooterDirective,
    CCSelectInputComponent,
    ModalConfirmationComponent,
    CustomModalHeaderDirective,
    CustomModalBodyDirective,
    CustomModalFooterDirective,
  ],
  declarations: [
    LoadingSpinnerComponent,
    CommaPipe,
    PermissionDirective,
    PreviewPdfComponent,
    PdfViewerComponent,
    SvgIconComponent,
    DropFileComponent,
    FormValidationDirective,
    FormSubmitValidationDirective,
    SignatureComponent,
    BackButtonDirective,
    ScrollTrackerDirective,
    DateRangePickerComponent,
    SignSignatureComponent,
    DepartmentDropdownComponent,
    SetThemeDirective,
    DeleteConfirmationModalComponent,
    LoaNameThPipe,
    HumanDatetimePipe,
    ImageUploadPreviewComponent,
    UploadFileExcelComponent,
    MemoPlaneTicketComponent,
    CommentComponent,
    ApprovalConfirmModalComponent,
    SelectSignMethodModalComponent,
    PaginationComponent,
    ImportProgressComponent,
    FeatureFlagDirective,
    OtpModalComponent,
    SecurePipe,
    MentionHighlightDirective,
    ContentEditableDirective,
    SafeHtmlPipe,
    BuddhistDatePipe,
    LinkifyPipe,
    ConfirmModalComponent,
    ExportExcelModalComponent,
    PasswordRuleBoxComponent,
    PhonePipe,
    PermissionNotFoundComponent,
    Department1ToCompanyPipe,
    GlobalAdminLabelPipe,
    CardComponent,
    CommonModalComponent,
    ImplementCustomModalHeaderDirective,
    ImplementCustomModalBodyDirective,
    ImplementCustomModalFooterDirective,
    CCSelectInputComponent,
    SplitPipe,
    ModalConfirmationComponent,
    CustomModalHeaderDirective,
    CustomModalBodyDirective,
    CustomModalFooterDirective,
  ],
  providers: [ImageProcessingService],
})
export class SharedModule {}
