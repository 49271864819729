import { Pipe, PipeTransform } from '@angular/core';
import { DepartmentService } from '../../modules/department/department.service';

@Pipe({
  name: 'globalAdminLabel',
})
export class GlobalAdminLabelPipe implements PipeTransform {
  transform(value) {
    if (value === 'Admin') {
      return 'Global Admin';
    }
    return value;
  }
}
