import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  memoTypeItem() {
    return [
      {
        label: 'Advance Voucher',
        value: 'advance_voucher',
        context: {
          display_name: 'ใบสำคัญจ่ายเงินทดรอง',
          display_name_en: 'Advance Voucher',
        },
      },
      {
        label: 'External',
        value: 'external',
        context: {
          display_name: 'แบบขออนุมัติภายนอก',
          display_name_en: 'External',
        },
      },
      {
        label: 'Internal',
        value: 'internal',
        context: {
          display_name: 'แบบขออนุมัติภายใน',
          display_name_en: 'Internal',
        },
      },
      {
        label: 'Payment Voucher',
        value: 'payment_voucher',
        context: {
          display_name: 'ใบสำคัญจ่าย',
          display_name_en: 'Payment Voucher',
        },
      },
      {
        label: 'Petty Cash Voucher',
        value: 'petty_cash_voucher',
        context: {
          display_name: 'ใบสำคัญจ่ายเงินสดย่อย',
          display_name_en: 'Petty Cash Voucher',
        },
      },
      {
        label: 'Purchase Request',
        value: 'purchase_request',
        context: {
          display_name: 'แบบขออนุมัติการซื้อ',
          display_name_en: 'Purchase Request',
        },
      },
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
      {
        label: 'Service Request',
        value: 'service_request',
        context: {
          display_name: 'คำขอบริการ',
          display_name_en: 'Service Request',
        },
      },
    ];
  }

  changeToGlobalAdminLabel(items) {
    return (items || []).map((r) => {
      if (r.label === 'Admin') {
        r.label = 'Global Admin';
      }
      return r;
    });
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
