<div class="position-relative "
    (clickOutside)="isShow = false">
  <div class="row">
    <div class="col select pointer"
        [ngClass]="{'straight-bottom': isShow, 'disabled': disable}"
        (click)="showDropdown()">
      <ng-container *ngIf="checkAllDepartment; else notAllDepartment">
        <span class="item">
          <span class="pointer close-border"
              style="z-index: 100;"
              (click)="closeAllDepartment()"
              *ngIf="multiple">
            ×</span>
          <span class="pl-1">
            {{translate.currentLang === 'th' ? ALL_DATA.label : ALL_DATA.label_en}}
          </span>
        </span>
      </ng-container>
      <ng-template #notAllDepartment>
        <span *ngFor="let item of items; let i = index"
            class="item">
          <span class="pointer close-border"
              style="z-index: 100;"
              (click)="deleteItem(i)"
              *ngIf="multiple">
            ×</span>
          <span class="pl-1">
            {{ item.label }}
          </span>
        </span>
      </ng-template>

      <span *ngIf="items.length === 0"
          class="placeholder"> {{ placeholder | translate}}</span>
      <i class="pt-2 fal fa-chevron-down position-absolute grey pt-0"
          [ngClass]="{'fa-chevron-up': isShow}"
          style="right: 0; padding: 10px; font-size:12px"></i>
      <i class="fas fa-times red position-absolute pointer"
          *ngIf="!multiple && items.length > 0"
          style="right: 7%;
                top: 12px; font-size: 10px;"
          (click)="clearValue()"></i>
    </div>
  </div>
  <div class="border dropdown border-top-0 d-none"
      [ngClass]="{'d-block': isShow && nodes}">
    <tree-root #treeRoot
        [focused]="true"
        (activate)="onActivate($event)"
        (select)="nodeChecked($event.node, true)"
        (deselect)="nodeChecked($event.node, false)"
        [nodes]="nodes"
        [options]="options">
      <ng-template #treeNodeTemplate
          let-node>
        <ng-container>
            {{translate.currentLang === 'th' ? node.data.label : node.data.label_en }}
        </ng-container>
      </ng-template>
    </tree-root>
  </div>
</div>