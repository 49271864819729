import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  DropdownResponse,
  DropdownService,
} from '../../service/dropdown.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-cc-select-input',
  templateUrl: './cc-select-input.component.html',
  styleUrls: ['./cc-select-input.component.scss'],
})
export class CCSelectInputComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  ccList$: Observable<any>;
  ccListInput$ = new Subject<string>();
  loading$ = new BehaviorSubject<boolean>(false);
  ccGroupList: any[] = [];

  lang$ = this.translate.onLangChange.pipe(
    takeUntil(this.destroy$),
    pluck('lang'),
    distinctUntilChanged(),
  );

  private _excludeCCIds = [];
  excludeCCIds$ = new BehaviorSubject<number[]>([]);
  @Input() set excludeCCIds(v: number[]) {
    this._excludeCCIds = v;
    this.excludeCCIds$.next(this._excludeCCIds);
  }
  get excludeCCIds(): number[] {
    return this._excludeCCIds;
  }

  @Output() selected = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit() {
    this.getCCList();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async getCCList() {
    try {
      this.ccGroupList = (await this.getAllCCGroupList()).filter(
        (v) => !this.excludeCCIds.includes(v?.value),
      );
    } catch (err) {
      this.ccGroupList = [];
    }
    const initialList$ = of(this.ccGroupList);

    this.ccList$ = combineLatest([
      this.excludeCCIds$,
      merge(
        initialList$, // when user didn't search use initial
        this.ccListInput$.pipe(
          distinctUntilChanged(),
          debounceTime(500),
          tap(() => this.loading$.next(true)),
          switchMap((query) => {
            if (!query) {
              // when user delete query all, use initial
              return initialList$;
            }
            // when user type query, search from backend
            return this.queryCCList(query);
          }),
          catchError(() => of([])),
          tap(() => this.loading$.next(false)),
        ),
      ),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([excludeIds, items]) => {
        // search items exclude with excludeIds
        return items.filter(
          ({ value }) => !excludeIds.includes(value),
        );
      }),
    );
  }

  queryCCList(query: string): Observable<any> {
    return this.dropdownService
      .getDropdown({
        type: 'cc',
        query,
      })
      .pipe(
        map((dropdown: DropdownResponse) => {
          return (dropdown.cc ?? []).filter(
            (v) => !this.excludeCCIds.includes(v?.value),
          );
        }),
      );
  }

  getAllCCGroupList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.dropdownService
        .getDropdown({
          type: 'cc',
          exclude_cc_people: true,
          no_limit: true,
        })
        .pipe(
          tap(() => this.loading$.next(true)),
          catchError(() => of([])),
          map((dropdown: DropdownResponse) => dropdown.cc),
          tap(() => this.loading$.next(false)),
        )
        .subscribe({
          next: (ccGroups) => {
            resolve(ccGroups);
          },
          error: () => {
            reject([]);
          },
        });
    });
  }

  onCCChange(cc: CCList, ref: NgSelectComponent): void {
    if (!cc) {
      return;
    }
    const formattedCC = {
      id: cc?.id,
      content_type: cc?.content_type ?? cc?.context?.content_type,
      cc_id: cc?.cc_id ?? cc?.value,
      label: cc?.label,
      is_cc_group: cc?.context?.is_group,
      context: cc?.context,
    };
    this.selected.emit(formattedCC);
    ref.handleClearClick();
  }
}

export type CCList = {
  label: string;
  value: number;
  context: Context;
  text: string;
  content_type: number;
  is_group: boolean;
  id: number;
  cc_id: number;
};

type Context = {
  text: string;
  content_type: number;
  is_group: boolean;
};
