import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  transform(val: string, delimiter: string): string[] {
    return val.split(delimiter).map((v) => v.trim());
  }
}
