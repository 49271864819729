import { Pipe, PipeTransform } from '@angular/core';
import { DepartmentService } from '../../modules/department/department.service';

@Pipe({
  name: 'dept1ToCompany',
  pure: false,
})
export class Department1ToCompanyPipe implements PipeTransform {
  constructor(private service: DepartmentService) {}

  transform(value) {
    if (!this.service.isDepartmentLevel1) {
      return value;
    }
    return mapDeptToCompany?.[value] ?? value;
  }
}

const mapDeptToCompany = {
  'DEPARTMENT.SEARCH': 'company.SEARCH',
  'DASHBOARD.DEPARTMENT': 'company.COMPANY',
  'DEPARTMENT.DEPARTMENT-HEADER-NAME':
    'company.DEPARTMENT-HEADER-NAME',
  'DEPARTMENT.CREATE-NEW-DEPARTMENT': 'company.CREATE-NEW-DEPARTMENT',
  'DEPARTMENT.DEPARTMENT-NAME': 'company.DEPARTMENT-NAME',
  'DEPARTMENT.DEPARTMENT-NAME-EN': 'company.DEPARTMENT-NAME-EN',
  'DEPARTMENT.DEPARTMENT-CODE': 'company.DEPARTMENT-CODE',
  'DEPARTMENT.EDIT-DEPARTMENT': 'company.EDIT-DEPARTMENT',
  'DEPARTMENT.DEPARTMENT-INITIAL': 'company.DEPARTMENT-INITIAL',
};
