import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeDropdownAccessServiceComponent } from './tree-dropdown-access-service.component';
import { TranslateModule } from '@ngx-translate/core';
import { TreeModule } from '@circlon/angular-tree-component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [TreeDropdownAccessServiceComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TreeModule,
    ClickOutsideModule,
  ],
  exports: [TreeDropdownAccessServiceComponent],
})
export class TreeDropdownAccessServiceModule {}
